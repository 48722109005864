import axios from "axios";
import { useState } from "react";

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use((req) => {
  req.headers["Content-Type"] = "application/json";
  return req;
});

export { axiosInstance };

const localBaseUrl = 'http://acme.localhost:8000'

export const getDomainList = (env: string) => {
  if (env === 'local')
    return axiosInstance.get(`${localBaseUrl}/api/domain/list/`);
  else
    return axiosInstance.get(`https://admin.${env}.kamet.ai/api/domain/list/`);
};


export const TriggerEvent = (domain: string, env: string, data: any) => {
  if (env === 'local')
    return axiosInstance.post(`${localBaseUrl}/api/event/trigger/`, data);
  else
    return axiosInstance.post(
      `https://${domain}.${env}.kamet.ai/api/event/trigger/`,
      data
    );
};



export const getEventsList = (
  env: string,
  domain: string,
  machine: string,
  pageNumber: number | null
) => {
  if (env === 'local')
    return axiosInstance.get(`${localBaseUrl}/api/events/history/?name=${machine}${pageNumber ? `&page=${pageNumber}` : ""
      }`);
  else

    return axiosInstance.get(
      `https://${domain}.${env}.kamet.ai/api/events/history/?name=${machine}${pageNumber ? `&page=${pageNumber}` : ""
      }`
    ); //TODO: change api end point
};

// get provider list
export const getProviderList = (env: string, domain: string) => {
  if (env === 'local')
    return axiosInstance.get(`${localBaseUrl}/api/provider/`);
  else
    return axiosInstance.get(`https://${domain}.${env}.kamet.ai/api/provider/`);
};

// update event
export const updateEventStatus = (domain: string, env: string, data: any) => {
  if (env === 'local')
    return axiosInstance.post(`${localBaseUrl}/api/events/status/change/`, data);
  else
    return axiosInstance.post(
      `https://${domain}.${env}.kamet.ai/api/events/status/change/`,
      data
    );
};

// update event
export const updateAnalytics = (domain: string, env: string, data: any) => {
  if (env === 'local')
    return axiosInstance.post(`${localBaseUrl}/api/events/analytics/`, data);
  else
    return axiosInstance.post(
      `https://${domain}.${env}.kamet.ai/api/events/analytics/`,
      data
    );
};

// update event
export const resetEvents = (domain: string, env: string) => {
  if (env === 'local')
    return axiosInstance.delete(`${localBaseUrl}/api/events/reset/data`);
  else
    return axiosInstance.delete(
      `https://${domain}.${env}.kamet.ai/api/events/reset/data/`
    );
};
