import React, { ChangeEvent, useEffect, useState } from "react";
import BackArrow from "../../assets/backArrow";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant.json";
import Button from "../../components/Button/Button";
import TimePicker from "../../components/TimePicker/TimePicker";
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import DropDown from "../../components/DropDownInput/DropDownInput";
import constants from "../../constants/contents.json";
import Pagination from "../../components/Pagination/Paginator";
import {
  getDomainList,
  getEventsList,
  updateEventStatus,
} from "../../services/Api";
import ErrorIcon from "../../assets/ErrorIcon";
import {
  convertDateFormat,
  convertTo24Hour,
  convertToUtc,
  envOptions,
} from "../../services/utils";
import Loader from "../../components/Loader/Loader";
import moment, { now } from "moment-timezone";

const History = () => {
  const navigate = useNavigate();
  const [env, setEnv] = useState<any>();
  const [domain, setDomain] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [riskData, setRiskData] = useState<any>();

  // State to manage selected time and date for each row based on id
  const [selectedDateTime, setSelectedDateTime] = useState<{
    [key: number]: { date: Date; time: string };
  }>({});

  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState("");
  const machineRange = constants.machineNames;
  const [domainRange, setDomainRange] = useState<
    { value: any; label: string }[]
  >([]);
  // ---------------------------------------timezone------------
  const moments = require("moment-timezone");
  const timeZones = moments.tz.names();

  // Convert timeZones into options format
  const timeZoneOptions = timeZones.map((tz: any) => ({
    value: tz,
    label: tz,
  }));
  const [selectedTimezone, setSelectedTimezone] = useState("US/Pacific");
  const today = moments().tz(selectedTimezone).startOf("day").toDate();
  const maxDate = today;
  const handleTimezoneChange = (e: any) => {
    setSelectedTimezone(e.target.value);
  };
  // -------------timezone----------------------
  useEffect(() => {
    if (selectedMachine) {
      getEvents();
    }
  }, [selectedMachine, selectedTimezone]);

  // Handler for time change
  const handleTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    setSelectedDateTime((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        time: event.target.value,
      },
    }));
  };

  // Handler for date change
  const handleDateChange = (date: Date, id: number) => {
    setSelectedDateTime((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        date: date,
      },
    }));
  };

  const handlePageChange = async (e: any) => {
    const pageNumber = e.selected + 1;
    if (selectedMachine && pageNumber) {
      const response = await getEventsList(
        env,
        domain,
        selectedMachine,
        pageNumber
      );
      setRiskData(response.data.data.results);
    }
  };
  // get event list api
  const getEvents = async () => {
    setLoading(true);
    try {
      const response = await getEventsList(env, domain, selectedMachine, null);
      if (response.data.data) {
        setRiskData(response.data.data.results);
        setTotalPages(response.data.data.total_pages);
      } else {
        setRiskData("");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 404) {
          setRiskData("");
          setLoading(false);
        }
      }
      console.error("Error fetching response:", error);
    }
  };

  // get domain api
  const getDomains = async (env: string) => {
    try {
      const response = await getDomainList(env);
      const dataRange = response.data.data?.map((item: any) => ({
        label: item.schema_name && item.schema_name,
        value: item.schema_name && item.schema_name,
      }));
      setDomainRange(dataRange);
    } catch (error) {
      console.error("Error fetching domains:", error);
    }
  };
  // update event api
  const updateEvent = async (id: string, date: any, time: any) => {
    const currentTime = moment(time, "HH:mm").utc();
    const currentTimeInUtc = currentTime.format("HH:mm");
    const convertedDateTime = convertToUtc(time, date, selectedTimezone);

    const data = {
      id: id,
      end_date: convertedDateTime?.utcDate,
      end_time: convertedDateTime?.utcTime,
    };
    try {
      const response = await updateEventStatus(domain, env, data);
      getEvents();
    } catch (error) {
      console.error("Error fetching domains:", error);
    }
  };
  if (loading === true) return <Loader />;
  // convert time based on timezone
  const convertToTimezone = (date: any, time: any, timezone: string) => {
    // Combine the date and time into a single string
    const utcDateTime = `${date} ${time} UTC`;
    // Parse the UTC date-time string into the specified timezone
    const convertedDateTime = new Date(utcDateTime).toLocaleString("en-US", {
      timeZone: timezone,
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return {
      converted_time: convertedDateTime.split(",")[1], // Format as needed
      converted_date: convertedDateTime.split(",")[0], // Format as needed
    };
  };
  return (
    <div className="px-10 lg:px-24 my-10">
      <div className="md:flex md:justify-between gap-16">
        <div className="flex items-center">
          <BackArrow
            className="cursor-pointer"
            onClick={() => navigate(RouteConstants.DASHBOARD)}
          />
          <div className="flex justify-center items-center gap-2">
            <h1 className="text-2xl font-semibold text-neutral-950">
              Risk Details History
            </h1>
            <div
              className="cursor-pointer"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <ErrorIcon className="text-2xl text-neutral-300" />
              {showTooltip && (
                <div className="flex items-center leading-6 absolute z-10 py-3 px-5 right-0 md:left-6 top-2 mb-2 w-[300px] md:w-max text-sm text-neutral-950 bg-neutral-0 border border-neutral-200 rounded shadow-lg">
                  Once you select the organization and machine, this page will
                  display all the details of ongoing issues
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          <div className="w-[215px]">
            <DropDown
              placeholder="Select Timezone"
              options={timeZoneOptions}
              onChange={handleTimezoneChange}
              value={selectedTimezone}
            />
          </div>
          <DropDown
            placeholder="Select environment *"
            options={envOptions}
            onChange={(e) => {
              getDomains(e.target.value);
              setEnv(e?.target?.value);
            }}
            value={env}
          />
          <DropDown
            placeholder="Select domain *"
            options={domainRange}
            onChange={(e) => {
              setDomain(e.target.value);
            }}
            value={domain}
          />

          <DropDown
            placeholder="Select Machine *"
            options={machineRange}
            onChange={(e) => setSelectedMachine(e.target.value)}
            value={selectedMachine}
          />
        </div>
      </div>

      <div className="overflow-x-auto pt-10">
        <table className="w-full text-center rtl:text-right text-neutral-500 text-base">
          <thead className="text-sm text-neutral-700 uppercase bg-neutral-100">
            <tr>
              <th scope="col" className="px-6 py-3">
                Event name
              </th>
              <th scope="col" className="px-6 py-3">
                Event type
              </th>
              <th scope="col" className="px-6 py-3">
                Start date
              </th>
              <th scope="col" className="px-6 py-3">
                Start Time
              </th>
              <th scope="col" className="px-6 py-3">
                End date
              </th>
              <th scope="col" className="px-6 py-3">
                End Time
              </th>
              <th scope="col" className="px-6 py-3">
                Date to fix
              </th>
              <th scope="col" className="px-6 py-3">
                Time to fix
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {riskData?.length > 0 ? (
              riskData.map((item: any) => {
                const selectedDate = selectedDateTime[item.id]?.date || maxDate;
                const selectedTime =
                  selectedDateTime[item.id]?.time ||
                  new Date().toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                const convertedStartData = convertToTimezone(
                  item.start_date,
                  item.start_time,
                  selectedTimezone
                );
                const convertedEndData = convertToTimezone(
                  item.end_date,
                  item.end_time,
                  selectedTimezone
                );

                return (
                  <tr key={item.id} className="bg-white border-b">
                    <td className="px-3 py-3">{item?.risk.event}</td>
                    <td className="px-3 py-3">
                      {item?.risk?.category == 3
                        ? "High risk"
                        : item?.risk?.category == 2
                        ? "Medium risk"
                        : "-"}{" "}
                    </td>

                    <td className="px-3 py-3">
                      {convertedStartData?.converted_date}
                    </td>
                    <td className="px-3 py-3">
                      {convertedStartData?.converted_time}
                    </td>
                    <td className="px-3 py-3">
                      {item?.end_date ? convertedEndData?.converted_date : "-"}
                    </td>
                    <td className="px-3 py-3">
                      {item?.end_time ? convertedEndData?.converted_time : "-"}
                    </td>
                    <td>
                      {item?.status === false ? (
                        <div>
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date: any) =>
                              handleDateChange(date, item.id)
                            }
                            dateFormat="MMMM d, yyyy"
                            minDate={
                              new Date(convertedStartData?.converted_date)
                            }
                            maxDate={maxDate}
                            placeholderText="Select a date"
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="px-6 py-4">
                      {item?.status === false ? (
                        <TimePicker
                          value={selectedTime}
                          onChange={(e) => handleTimeChange(e, item.id)}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="px-6 py-4">
                      {item?.status === false ? (
                        <div className="flex justify-center">
                          <Button
                            variant={"danger"}
                            onClick={() => {
                              updateEvent(item.id, selectedDate, selectedTime);
                            }}
                            customWidth="w-[90px]"
                          >
                            Fix now
                          </Button>
                        </div>
                      ) : (
                        <div className="flex justify-center">
                          <Button
                            variant={"success"}
                            onClick={() => {}}
                            disabled={true}
                            customWidth="w-[90px]"
                          >
                            Fixed
                          </Button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={8}
                  className="px-6 py-20 text-base text-center bg-white"
                >
                  Nothing to show here
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {riskData?.length > 0 && (
        <div className="w-full py-5 flex items-center justify-end">
          <Pagination
            pageCount={totalPages}
            initialPage={0}
            onPageChange={(e: any) => handlePageChange(e)}
          />
        </div>
      )}
    </div>
  );
};

export default History;
