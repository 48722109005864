import Card from "../../../components/ThemeCard/Themecard";
import DropDown from "../../../components/DropDownInput/DropDownInput";
import constants from "../../../constants/contents.json";
import Button from "../../../components/Button/Button";
import { useState } from "react";
import TimePicker from "../../../components/TimePicker/TimePicker";
import moment from "moment";
import { UTCToLocalTimes } from "../../../services/utils";
interface IPayloadInterface {
  handleDropdownChange: any;
  handleMachineDetailsChange: any;
  removeMachine: any;
  addMachine: any;
  selectedValues: any;
  timezone:any;

}
const SafetyCard = (props: IPayloadInterface) => {
  const sensorRange = constants.sensorRange;
  const safetySensor = constants.saftySensor;
  const machineRange = constants.machineNames;

  // current time-----------------
  const currentTime = moment().format("HH:mm");

  return (
    <Card>
      <div className="grid grid-cols-2">
        <DropDown
          label={safetySensor.sensors}
          placeholder={safetySensor.selectVersion}
          options={sensorRange}
          onChange={(e) =>
            props.handleDropdownChange("safetySensor", e.target.value)
          }
          helpText={safetySensor.details}
        />
      </div>
      {props.selectedValues.safetySensorDetails.map(
        (machine: any, index: any) => (
          <div key={index} className="grid grid-cols-4 gap-4 mt-4">
            <DropDown
              label={`Machine *`}
              placeholder="Select Machine"
              options={machineRange}
              onChange={(e) =>
                props.handleMachineDetailsChange(
                  "safetySensorDetails",
                  index,
                  "name",
                  e.target.value
                )
              }
            />
            <DropDown
              label="Zone *"
              placeholder="Select Zone"
              options={constants.zoneRange}
              onChange={(e) =>
                props.handleMachineDetailsChange(
                  "safetySensorDetails",
                  index,
                  "location",
                  e.target.value
                )
              }
            />
            <div>
              <div>
                <TimePicker
                  label="Select Time"
                  value={machine.start_time}
                  onChange={(e) => {
                    const selectedTime = e.target.value;
                    // const utcTime = moment(selectedTime, "HH:mm")
                    //   .utc()
                    //   .format("HH:mm");
                    props.handleMachineDetailsChange(
                      "safetySensorDetails",
                      index,
                      "start_time",
                      selectedTime
                    );
                  }}
                />
              </div>
            </div>

            <div className="mt-8">
              <Button
                variant="secondary"
                onClick={() =>
                  props.removeMachine("safetySensorDetails", index)
                }
              >
                Remove
              </Button>
            </div>
          </div>
        )
      )}
      <div className="mt-5">
        <Button
          variant="primary"
          onClick={() => props.addMachine("safetySensorDetails")}
        >
          Add Machine
        </Button>
      </div>
    </Card>
  );
};

export default SafetyCard;
