import { useNavigate } from "react-router-dom";
import BackArrow from "../../assets/backArrow";
import Button from "../../components/Button/Button";
import Efficienycard from "./EfficienyCard";
import RoiCard from "./RoiCard";
import UitlizationCard from "./UitlizationCard";
import UptimeCard from "./UptimeCard";
import RouteConstants from "../../constants/RouteConstant.json";
import Constants from "../../constants/contents.json";
import { useEffect, useState } from "react";
import { getDomainList, updateAnalytics } from "../../services/Api";
import DropDown from "../../components/DropDownInput/DropDownInput";
import { envOptions } from "../../services/utils";
import Toast from "../../components/Toast/Toast";
import constants from "../../constants/contents.json";
import Loader from "../../components/Loader/Loader";

type AlertType = "success" | "error" | "information" | "warning";

const AnalyticsSimulator = () => {
  const [formData, setFormData] = useState({
    uptime_hours_weekly: "",
    uptime_percent_previous: "",
    utilization_hours_weekly: "",
    utilization_percent_previous: "",
    efficiency_percent_previous: "",
    ro1_week_1: "",
    ro1_week_2: "",
    ro1_week_current: "",
  });
  const [env, setEnv] = useState<any>();
  const [domain, setDomain] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [domainRange, setDomainRange] = useState<
    { value: any; label: string }[]
  >([]);
  const [showAlertToast, setShowAlertToast] = useState(false);
  const [alertToastType, setAlertToastType] = useState<AlertType>("success");
  const [toastMessage, setToastMessage] = useState("");
  const [productiveTime, setProdcutiveTime] = useState<number>();
  const [showButton, setShowButton] = useState(false);
  const navigate = useNavigate();
  // Handle input changes from each card
  const handleInputChange = (field: any, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setShowButton(true)
  };
  // get domain api
  const getDomains = async (env: string) => {
    try {
      const response = await getDomainList(env);
      const dataRange = response.data.data?.map((item: any) => ({
        label: item.schema_name && item.schema_name,
        value: item.schema_name && item.schema_name,
      }));
      setDomainRange(dataRange);
    } catch (error) {
      console.error("Error fetching domains:", error);
    }
  };
  // submit analytics api
  const onClickSubmit = async () => {
    setLoading(true);

    try {
      const response = await updateAnalytics(domain, env, formData);
      if (response?.data?.detail === "success") {
        setAlertToastType("success");
        setToastMessage(constants.successMsg);
        setShowAlertToast(true);
        setLoading(false);
        setFormData({
          uptime_hours_weekly: "",
          uptime_percent_previous: "",
          utilization_hours_weekly: "",
          utilization_percent_previous: "",
          efficiency_percent_previous: "",
          ro1_week_1: "",
          ro1_week_2: "",
          ro1_week_current: "",
        });
        setShowButton(false)

      }

    } catch (error) {
      setAlertToastType("error");
      setToastMessage(constants.ErrorMsg);
      setShowAlertToast(true);
      setLoading(false);
    }
  };
  const onClickCancelToast = () => {
    setShowAlertToast(false);
  };

  useEffect(() => {
    let dynamicTime = Number(formData?.uptime_hours_weekly) * 7 * 8;
    setProdcutiveTime(dynamicTime);
  }, [formData?.uptime_hours_weekly]);

  if (loading === true) return <Loader />;

  return (
    <>
      <div className="px-10 md:px-24 my-10">
        <div className="flex gap-4 items-center mb-5 justify-between">
          <div className="flex gap-3">
            <BackArrow
              className="cursor-pointer"
              onClick={() => navigate(RouteConstants.DASHBOARD)}
            />

            <h1 className="text-2xl font-semibold text-neutral-950">
              Simulate Analytics
            </h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            <DropDown
              placeholder="Select environment *"
              options={envOptions}
              onChange={(e) => {
                getDomains(e.target.value);
                setEnv(e?.target?.value);
              }}
            />
            <DropDown
              placeholder="Select domain *"
              options={domainRange}
              onChange={(e) => {
                setDomain(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <UptimeCard
            staticData={Constants.analytics.uptime}
            onChange={(field, value) => handleInputChange(field, value)}
          />
        </div>

        <div className="mt-7">
          <UitlizationCard
            staticData={Constants.analytics.utilization}
            onChange={(field, value) => handleInputChange(field, value)}
            placeholder={`Enter the value less than ${productiveTime}`}
          />
        </div>
        <div className="mt-7">
          <Efficienycard
            staticData={Constants.analytics.efficiency}
            onChange={(field, value) => handleInputChange(field, value)}
          />
        </div>
        <div className="mt-7">
          <RoiCard
            staticData={Constants.analytics.roiIncrease}
            onChange={(field, value) => handleInputChange(field, value)}
          />
        </div>
        <div className="mt-5">
          <Button variant={showButton?"primary":"disable"} onClick={onClickSubmit}>
            {Constants.analytics.buttonText}
          </Button>
        </div>
        {/* -------------------- ROI section ends */}
      </div>
      {showAlertToast && (
        <div className="fixed bottom-2 left-40 w-full z-50">
          <Toast
            showToast={showAlertToast}
            Message={toastMessage}
            cancelToast={onClickCancelToast}
            type={alertToastType}
          />
        </div>
      )}
    </>
  );
};

export default AnalyticsSimulator;
