import { useNavigate } from "react-router-dom";
import Button from "../components/Button/Button";
import RouteConstants from "../constants/RouteConstant.json";
import DropDown from "../components/DropDownInput/DropDownInput";
import {
  getDomainList,
  getProviderList,
  resetEvents,
  TriggerEvent,
} from "../services/Api";
import { useEffect, useState } from "react";
import Toast from "../components/Toast/Toast";
import Constants from "../constants/contents.json";
import moment from "moment-timezone"; // Make sure to import moment-timezone
const timezone = "US/Pacific";
const SimulatorDashboard = () => {
  type AlertType = "success" | "error" | "information" | "warning";

  const navigate = useNavigate();
  const [domainRange, setDomainRange] = useState<
    { value: any; label: string }[]
  >([]);
  const [env, setEnv] = useState<any>("");
  const [providerId, setProviderId] = useState<any>(null);
  const [providerList, setProviderList] = useState<any>();
  const [providerName, setProviderName] = useState("");
  const [domain, setDomain] = useState<any>("");
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const [simuationType, setSimulationType] = useState("");
  const [showAlertToast, setShowAlertToast] = useState(false);
  const [alertToastType, setAlertToastType] = useState<AlertType>("success");
  const [toastMessage, setToastMessage] = useState("");
  // state for auto smiulation
  const [autoSimulateButton, setAutoSimulateButton] = useState(false);
  const [autoSimulateEnv, setAutoSimulateEnv] = useState("");
  const [autoSimulateDomain, setAutoSimulateDomain] = useState("");
  const [autoSimulateProvider, setAutoSimulateProvider] = useState("");
  // state for reset simulation
  const [resetEnv, setResetEnv] = useState("");
  const [resetDomain, setResetDomain] = useState("");
  const [enableResetButton, setEnableResetButton] = useState(false);
  const moments = require("moment-timezone");
  const timeZones = moments.tz.names();

  const timeZoneOptions = timeZones.map((tz: any) => ({
    value: tz,
    label: tz,
  }));
  const handleTimezoneChange = (e: any) => {
    setSelectedTimezone(e.target.value);
  };
  const [selectedTimezone, setSelectedTimezone] = useState("US/Pacific");
  // get domains api
  const getDomains = async (env: string) => {
    try {
      const response = await getDomainList(env);
      const dataRange = response.data.data?.map((item: any) => ({
        label: item.schema_name && item.schema_name,
        value: item.schema_name && item.schema_name,
      }));
      setDomainRange(dataRange);
    } catch (error) {
      console.error("Error fetching domains:", error);
    }
  };
  const envOptions = [
    {
      label: "Local",
      value: "local"
    },
    {
      label: "Dev",
      value: "dev",
    },
    {
      label: "QA",
      value: "qa",
    },
    {
      label: "Stage",
      value: "beta",
    },
    {
      label: "Acme",
      value: "acme",
    },
  ];

  useEffect(() => {
    if (env && domain) {
      getProviders();
    }
  }, [env, domain]);

  // function to get providerList
  const getProviders = async () => {
    const response = await getProviderList(env, domain);
    const listArray = response.data.data.results;
    const providers = listArray?.map((list: any) => {
      return {
        label: list.name,
        value: list.id,
      };
    });
    setProviderList(providers);
  };

  // validation for environment
  const validateEnv = (env: any) => {
    if (env) {
      return true;
    } else {
      return false;
    }
  };

  // validation for domain name
  const validateDomain = (domainName: any) => {
    if (domainName) {
      return true;
    } else {
      return false;
    }
  };

  // validation for provider
  const validateProvider = (providerName: any) => {
    if (providerName) {
      return true;
    } else {
      return false;
    }
  };

  // validate dropdown and enable submit button
  useEffect(() => {
    const envValidation = validateEnv(env);
    const domainValidation = validateDomain(domain);
    const providerValidation = validateProvider(providerId);

    if (envValidation && domainValidation && providerValidation) {
      if (simuationType === "manual_simulate") {
        setEnableSubmitButton(true);
      } else if (simuationType === "auto_simulate") {
        setAutoSimulateButton(true);
      }
    } else if (
      envValidation &&
      domainValidation &&
      simuationType === "reset_simulation"
    ) {
      setEnableResetButton(true);
    }
  }, [env, domain, providerId, simuationType]);

  const convertTime = (h: any, m: any, s: any) => {
    const baseTime = moment.utc().set({
      hour: h,
      minute: m,
      second: s,
    });
    return baseTime.tz(selectedTimezone).format("HH:mm");
  };

  // function on submit button ->  redirecting to selected providers simulator page
  const onHandleSubmit = () => {
    if (enableSubmitButton) {
      let routeName = "";
      if (providerName.toLowerCase() === "viam") {
        routeName = RouteConstants.VIAM;
      } else if (providerName.toLowerCase() === "first resonance") {
        routeName = RouteConstants.ION;
      }
      {
        navigate(routeName, {
          state: {
            domain: domain,
            environment: env,
            provider: Number(providerId),
          },
        });
      }
    } else {
      return false;
    }
  };

  // Viam static data
  const viamStaticData = {
    events: [
      {
        risk: {
          domain: domain,
          event: "Software",
          value: "1",
          provider: providerId,
          date: moment().subtract(3, "days").tz(selectedTimezone).format("YYYY-MM-DD")
        },
        machine_details: [
          {
            name: "Machine1",
            location: "Zone B",
            start_time: convertTime(8, 0, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(3, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "1",
          },
          {
            name: "Machine2",
            location: "Zone B",
            start_time: convertTime(8, 0, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(3, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "1",
          },
        ],
      },
      {
        risk: {
          domain: domain,
          event: "Software",
          value: "1",
          provider: providerId,
          date: moment().subtract(4, "days").tz(selectedTimezone).format("YYYY-MM-DD")
        },
        machine_details: [
          {
            name: "Machine3",
            location: "Zone B",
            start_time: convertTime(8, 0, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(4, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "1",
          },
        ],
      },
      {
        risk: {
          domain: domain,
          event: "Safety Sensor",
          value: "0",
          provider: providerId,
          date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD")
        },
        machine_details: [
          {
            name: "Machine9",
            location: "Zone B",
            start_time: convertTime(8, 0, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "0",
          },
        ],
      },
      {
        risk: {
          domain: domain,
          event: "Camera Sensor",
          value: "0",
          provider: providerId,
          date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD")
        },
        machine_details: [
          {
            name: "Machine10",
            location: "Zone B",
            start_time: convertTime(8, 0, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "0",
          },
        ],
      },
      {      
        risk: {
          domain: domain,
          event: "Speed",
          value: "11",
          provider: providerId,
          date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD")
        },
        machine_details: [
          {
            name: "Machine9",
            location: "Zone B",
            start_time: convertTime(8, 0, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "11",
          },
        ],
      },
      {
        risk: {
          domain: domain,
          event: "Payload",
          value: "21",
          provider: providerId,
          date: moment().subtract(0, "days").tz(selectedTimezone).format("YYYY-MM-DD")
        },
        machine_details: [
          {
            name: "Machine9",
            location: "Zone B",
            start_time: convertTime(8, 0, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(0, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "11",
          },
        ],
      },
      {
        risk: {
          domain: domain,
          event: "Location",
          value: "9",
          provider: providerId,
          date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD")
        },
        machine_details: [
          {
            name: "Machine9",
            location: "Zone A",
            start_time: convertTime(10, 30, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "9",
          },
        ],
      },
    ],
  };

  // ion static data
  const ionStaticData = {
    events: [
      {
        risk: {
          domain: domain,
          event: "Configuration",
          value: "3",
          provider: providerId,
          date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),

        },
        machine_details: [
          {
            name: "Machine3",
            location: "Zone C",
            start_time: convertTime(12, 45, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "3",
          },
        ],
      },
      {
        risk: {
          domain: domain,
          event: "Calibration",
          value: "2",
          provider: providerId,
          date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
        },
        machine_details: [
          {
            name: "Machine4",
            location: "Zone G",
            start_time: convertTime(15, 45, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "2",
          },
          {
            name: "Machine5",
            location: "Zone G",
            start_time: convertTime(15, 45, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "2",
          },
          {
            name: "Machine6",
            location: "Zone G",
            start_time: convertTime(15, 45, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "2",
          },
        ],
      },
      {
        risk: {
          domain: domain,
          event: "Calibration",
          value: "3",
          provider: providerId,
          date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
        },
        machine_details: [
          {
            name: "Machine5",
            location: "Zone G",
            start_time: convertTime(15, 45, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "3",
          },
        ],
      },
      {
        risk: {
          domain: domain,
          event: "Authorization",
          value: "3",
          provider: providerId,
          date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),

        },
        machine_details: [
          {
            name: "Machine7",
            location: "Zone C",
            start_time: convertTime(9, 15, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "3",
          },
          {
            name: "Machine8",
            location: "Zone C",
            start_time: convertTime(9, 15, 0), // Convert the start_time to Pacific timezone
            start_date: moment().subtract(-1, "days").tz(selectedTimezone).format("YYYY-MM-DD"),
            provider: providerId,
            value: "3",
          },
        ],
      },
    ],
  };

  const onChangeAutoSminulateProvider = (e: any) => {
    const name = e.target.options[e.target.selectedIndex].text;
    setProviderId(e.target.value);
    setProviderName(name);
    validateProvider(e.target.value);
    setSimulationType("auto_simulate");
    setAutoSimulateProvider(e.target.value);
  };

  // on submit  auto simulation button
  const onHandleAutoSimulate = async () => {
    let simulationData;
    if (providerName.toLowerCase() === "viam") {
      simulationData = viamStaticData;
    } else if (providerName.toLowerCase() === "first resonance") {
      simulationData = ionStaticData;
    }
    try {
      const response = await TriggerEvent(domain, env, simulationData);
      if (response?.data?.detail === "success") {
        setAlertToastType("success");
        setToastMessage(Constants.successMsg);
        setShowAlertToast(true);
      }
    } catch (error) {
      setAlertToastType("error");
      setToastMessage(Constants.ErrorMsg);
      setShowAlertToast(true);
    }
    setEnv("");
    setDomain("");
    setProviderId("");
    setAutoSimulateEnv("");
    setAutoSimulateDomain("");
    setAutoSimulateProvider("");
    setAutoSimulateButton(false);
  };

  // onclick reset button
  const onHandleResetSimulation = () => {
    try {
      const response = resetEvents(domain, env);
      setAlertToastType("success");
      setToastMessage(Constants.resetMsg);
      setShowAlertToast(true);
    } catch (error) {
      setAlertToastType("error");
      setToastMessage(Constants.ErrorMsg);
      setShowAlertToast(true);
    }
    setEnv("");
    setDomain("");
    setProviderId("");
    setResetDomain("");
    setResetEnv("");
    setEnableResetButton(false);
  };

  // toast concel function
  const onClickCancelToast = () => {
    setShowAlertToast(false);
  };

  return (
    <>
      <div className="px-10 md:px-24 my-10">
        <section>
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold text-neutral-950 ">
              Simulate Dashboard
            </h1>
            <div className="flex gap-4">
              <Button onClick={() => navigate(RouteConstants.ANALYTICS)}>
                Simulate Analytics
              </Button>
              <Button
                variant={"secondary"}
                onClick={() => navigate(RouteConstants.HISTORY)}
              >
                History
              </Button>
            </div>
          </div>
        </section>
        <section>
          <h1 className="text-xl font-semibold text-neutral-950 mt-10 mb-5">
            Simulate Issues
          </h1>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <DropDown
                label={`Select the environment *`}
                placeholder="Select environment"
                options={envOptions}
                onChange={(e) => {
                  getDomains(e.target.value);
                  setEnv(e?.target?.value);
                  validateEnv(e.target.value);
                }}
              />
            </div>
            <div>
              <DropDown
                label={`Select the Domain *`}
                placeholder="Select domain"
                options={domainRange}
                onChange={(e) => {
                  setDomain(e.target.value);
                  validateDomain(e.target.value);
                }}
              />
            </div>
            <div>
              <DropDown
                label="Select Provider *"
                placeholder="Select provider"
                options={providerList ? providerList : []}
                onChange={(e) => {
                  const name = e.target.options[e.target.selectedIndex].text;
                  setProviderId(e.target.value);
                  setProviderName(name);
                  validateProvider(e.target.value);
                  setSimulationType("manual_simulate");
                }}
              />
            </div>
            <div className="mt-8">
              <Button
                variant={enableSubmitButton ? "primary" : "disable"}
                onClick={onHandleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </section>
        <section>
          <h1 className="text-xl font-semibold text-neutral-950 mt-10 mb-5">
            Auto Simulate
          </h1>
          <div className="grid grid-cols-5 gap-4">
            <div>
              <DropDown
                label="Select Timezone *"
                placeholder="Select Timezone"
                options={timeZoneOptions}
                onChange={handleTimezoneChange}
                value={selectedTimezone}
              />
            </div>
            <div>
              <DropDown
                label={`Select the environment *`}
                placeholder="Select environment"
                options={envOptions}
                onChange={(e) => {
                  getDomains(e.target.value);
                  setEnv(e.target.value);
                  setAutoSimulateEnv(e?.target?.value);
                  validateEnv(e.target.value);
                }}
                value={autoSimulateEnv}
              />
            </div>
            <div>
              <DropDown
                label={`Select the Domain *`}
                placeholder="Select domain"
                options={domainRange}
                onChange={(e) => {
                  setDomain(e.target.value);
                  setAutoSimulateDomain(e.target.value);
                  validateDomain(e.target.value);
                }}
                value={autoSimulateDomain}
              />
            </div>

            <div>
              <DropDown
                label="Select Provider *"
                placeholder="Select provider"
                options={providerList ? providerList : []}
                onChange={(e) => {
                  onChangeAutoSminulateProvider(e);
                }}
                value={autoSimulateProvider}
              />
            </div>

            <div className="mt-8">
              <Button
                variant={autoSimulateButton ? "primary" : "disable"}
                onClick={onHandleAutoSimulate}
              >
                Simulate
              </Button>
            </div>
          </div>
        </section>

        <section>
          <h1 className="text-xl font-semibold text-neutral-950 mt-10 mb-5">
            Reset Simulation
          </h1>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <DropDown
                label={`Select the environment *`}
                placeholder="Select environment"
                options={envOptions}
                onChange={(e) => {
                  getDomains(e.target.value);
                  setEnv(e.target.value);
                  setResetEnv(e?.target?.value);
                  validateEnv(e.target.value);
                }}
                value={resetEnv}
              />
            </div>
            <div>
              <DropDown
                label={`Select the Domain *`}
                placeholder="Select domain"
                options={domainRange}
                onChange={(e) => {
                  setDomain(e.target.value);
                  setResetDomain(e.target.value);
                  validateDomain(e.target.value);
                  setSimulationType("reset_simulation");
                }}
                value={resetDomain}
              />
            </div>

            <div className="mt-8">
              <Button
                variant={enableResetButton ? "primary" : "disable"}
                onClick={onHandleResetSimulation}
              >
                Reset
              </Button>
            </div>
          </div>
        </section>

        {showAlertToast && (
          <div className="fixed bottom-2 left-40 w-full z-50">
            <Toast
              showToast={showAlertToast}
              Message={toastMessage}
              cancelToast={onClickCancelToast}
              type={alertToastType}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SimulatorDashboard;
