import Card from "../../components/ThemeCard/Themecard";
import Input from "../../components/InputComponent/Input";

interface IEfficiencyCard{
  staticData:any
  onChange: (field: string, value: number) => void; // Add onChange prop
}

const Efficienycard = (props:IEfficiencyCard) => {
  return (
    <>
      <section>
        <h2 className="text-lg font-semibold text-neutral-950 mb-2">
          {props.staticData.title}
        </h2>
        <Card>
          <div>
            <div className="grid grid-cols-3 gap-4">
              <Input
                label={props.staticData.label}
                placeholder={props.staticData.placeholder}
                type="number"
                onchange={(e) =>
                  props.onChange("efficiency_percent_previous", Number(e.target.value))
                }
              />
            </div>
          </div>
        </Card>
      </section>
    </>
  );
};

export default Efficienycard;
