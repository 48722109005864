import Card from "../../../components/ThemeCard/Themecard";
import DropDown from "../../../components/DropDownInput/DropDownInput";
import Constants from "../../../constants/contents.json";
import Button from "../../../components/Button/Button";
import TimePicker from "../../../components/TimePicker/TimePicker";
import { useState } from "react";
import moment from "moment";
import { UTCToLocalTimes } from "../../../services/utils";

interface IMachinePermissionInterface {
  handleDropdownChange: any;
  handleMachineDetailsChange: any;
  removeMachine: any;
  addMachine: any;
  selectedValues: any;
  timezone: any;
}

const MachineCalibartionCard = (props: IMachinePermissionInterface) => {
  const staticContent = Constants.ion;
  const machineNames = Constants.machineNames;
  const zone = Constants.zoneRange;

  // current time-----------------
  const currentTime = moment().format("HH:mm");

  return (
    <div>
      <Card>
        <h1 className="mb-2">{staticContent.thirdCard.question}</h1>
        <div className="grid grid-cols-3">
          <DropDown
            placeholder="Select an option"
            options={staticContent.thirdCard.optionList}
            onChange={(e) => {
              props.handleDropdownChange("machineCalibration", e.target.value);
            }}
            helpText={staticContent.thirdCard.helpText}
          />
        </div>

        {props.selectedValues.machineCalibrationDetails.map(
          (machine: any, index: number) => {
            return (
              <div className="grid grid-cols-4 gap-4 mt-5">
                <DropDown
                  label="Machine *"
                  placeholder="Select Machine"
                  options={machineNames}
                  onChange={(e) => {
                    props.handleMachineDetailsChange(
                      "machineCalibrationDetails",
                      index,
                      "name",
                      e.target.value
                    );
                  }}
                />
                <DropDown
                  label="Zone *"
                  placeholder="Select Zone"
                  options={zone}
                  onChange={(e) => {
                    props.handleMachineDetailsChange(
                      "machineCalibrationDetails",
                      index,
                      "location",
                      e.target.value
                    );
                  }}
                />
                <div>
                  <div>
                    <TimePicker
                      label="Select Time"
                      value={machine.start_time}
                      onChange={(e) => {
                        const selectedTime = e.target.value;
                        // const utcTime = moment(selectedTime, "HH:mm")
                        //   .utc()
                        //   .format("HH:mm");
                        props.handleMachineDetailsChange(
                          "machineCalibrationDetails",
                          index,
                          "start_time",
                          selectedTime
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="mt-8">
                  <Button
                    variant="secondary"
                    onClick={() =>
                      props.removeMachine("machineCalibrationDetails", index)
                    }
                  >
                    Remove
                  </Button>
                </div>
              </div>
            );
          }
        )}

        <div className="mt-5">
          <Button
            variant="primary"
            onClick={() => props.addMachine("machineCalibrationDetails")}
          >
            Add Machine
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default MachineCalibartionCard;
