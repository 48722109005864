import Input from "../../components/InputComponent/Input";
import Card from "../../components/ThemeCard/Themecard";

interface IRoiCard {
  staticData: any;
  onChange: (field: string, value: number) => void; // Add onChange prop
}

const RoiCard = (props: IRoiCard) => {
  return (
    <>
      <section>
        <h2 className="text-lg font-semibold text-neutral-950 mb-2">
          {props.staticData.title}
        </h2>
        <Card>
          <div>
            <p className="text-neutral-950 text-base font-medium mb-2">
              {props.staticData.description}
            </p>
            <div className="grid grid-cols-3 gap-4">
              <Input
                label={props.staticData.weekOne}
                placeholder={props.staticData.weekOne}
                type="number"
                onchange={(e) =>
                  props.onChange("ro1_week_1", Number(e.target.value))
                }
              />
              <Input
                label={props.staticData.weekTwo}
                placeholder={props.staticData.weekTwo}
                type="number"
                onchange={(e) =>
                  props.onChange("ro1_week_2", Number(e.target.value))
                }
              />
              <Input
                label={props.staticData.weekThree}
                placeholder={props.staticData.weekThree}
                type="number"
                onchange={(e) =>
                  props.onChange("ro1_week_current", Number(e.target.value))
                }
              />
            </div>
          </div>
        </Card>
      </section>
    </>
  );
};

export default RoiCard;
